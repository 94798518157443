import React from 'react';
import config from '@/config.json';
import MainScreenForm from '@Components/MainScreenForm';
import MainScreenList from '@Components/MainScreenList';
import { useTranslation } from 'react-i18next';
import { Section, SectionBox, SectionHeadline, SectionCaption, SectionWrapper } from './styled';

const DefaultTemplate = () => {
  const { t } = useTranslation();
  const brandName = config.DESCRIPTOR_NAME;
  const getCustomTextClass = (() => {
    switch (brandName) {
      case 'PROTECT247.SHOP':
      case 'PROTECT247.MOBI':
      case 'PROTECT247.LINK':
      case 'SEARQLE.SITE':
      case 'SEARQLE.CLICK':
      case 'SEARQLE.STORE':
      case 'SEARQLE.PRO':
      case 'SPOTTRACK.SHOP':
      case 'SPOTTRACK.PRO':
        return 'topWhiteText';
      default:
        return '';
    }
  })();

  return (
    <Section
      id="main"
      bgImage={`/images/main-screen/defaultTemplate/${config.TRADE_NAME.toLowerCase()}${config.DESCRIPTOR_DOMAIN.toLowerCase()}`}
    >
      <SectionWrapper>
        <SectionBox>
          <SectionCaption>
            <SectionHeadline className={getCustomTextClass}>
              {t(`mainSection.headline`)}
            </SectionHeadline>
            <MainScreenList />
          </SectionCaption>

          <MainScreenForm />
        </SectionBox>
      </SectionWrapper>
    </Section>
  );
};
export default DefaultTemplate;
