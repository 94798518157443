import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { FormButton } from '@Components/UI/Button';
import { Field } from '@Components/UI/Input';
import Select from '@Components/UI/Select';
import configureList from '@Components/MainScreenForm/optionsList.json';
import TextArea from '@Components/UI/TextArea';
import { useTranslation } from 'react-i18next';
import config from '@/config.json';
import { FormBox, FormStatus } from './styled';

// eslint-disable-next-line react/prop-types
const Form = ({ children, onSubmit, control, register, errors }) => {
  const template = config.TEMPLATE;
  const variant = `variant_${config?.VARIANT}`;
  const customColorClass = config?.COLOR_ACCENT ? 'customColorStyle' : '';

  return (
    <FormBox
      onSubmit={onSubmit}
      className={`${template} ${customColorClass}`}
      customColor={config?.COLOR_ACCENT}
      {...(config.VARIANT && { templateVariant: variant })}
    >
      {children.map((child) => {
        return child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register,
                key: child.props.name,
                error: errors[child.props.name],
                control,
              },
            })
          : child;
      })}
    </FormBox>
  );
};

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const submitForm = async (formData) => {
  try {
    const url = config?.API_EVENT_AGREGATOR_HOST ? '/event-ticket' : `/api/zendesk/ticket`;
    const response = await fetch(url, {
      method: 'post',
      body: JSON.stringify({
        ...formData,
      }),
      headers: {
        'Content-type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        descriptor: `${config.DESCRIPTOR_NAME}`,
        'x-id': 'descriptors_sites',
      },
    });

    return await response.json();
  } catch (err) {
    return err;
  }
};

const FormMessage = (message) => {
  const { t } = useTranslation();
  const { status } = message;

  return status !== null ? (
    <FormStatus className={status}>{t(`form.${status}`)}</FormStatus>
  ) : (
    <></>
  );
};

const MainSectionForm = () => {
  const { t, i18n } = useTranslation();
  const [formIsSuccess, setFormIsSuccess] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [amountValue, setAmountValue] = useState('');
  const template = config.TEMPLATE;
  const oldTemplate = ['oldTemplate'].includes(template);

  const amountHandler = (event) => {
    setAmountValue(event.target.value.replaceAll(/[^0-9.]/g, ''));
  };

  const formatDate = (date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  };

  const {
    control,
    handleSubmit,
    register,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: { policy: !oldTemplate },
    mode: 'all',
  });

  const onSubmit = (data) => {
    const { firstName, lastName, email, subject, amount: transAmount, date, message } = data;

    const transDate = date ? formatDate(date) : '';
    const subjectId = configureList?.find((item) => item?.title === subject)?.value;

    const bodyRequest = {
      message: message || '.',
      subject,
      name: `${firstName} ${lastName}`,
      email,
      locale: i18n.language,
      tags: ['descriptors_sites', 'cancellation_priority', `${config.DESCRIPTOR_TAG}`],
      custom_fields: [
        { id: 360019200837, value: `${config.DESCRIPTOR_TAG}` },
        { id: 360013372638, value: transAmount },
        { id: 7676100429981, value: transDate },
        { id: 7787325361949, value: subjectId },
      ],
      descriptorTag: `${config.DESCRIPTOR_TAG}`,
    };

    setIsFetching(true);
    resetField('firstName');
    resetField('lastName');
    resetField('email');
    resetField('subject');
    setAmountValue(() => '');
    resetField('date');
    resetField('message');
    submitForm(bodyRequest).then((response) => {
      if (response?.statusCode !== 200) {
        setFormIsSuccess('error');
      } else {
        setFormIsSuccess('success');
      }
      setIsFetching(false);
    });
  };

  const formats = {
    email: {
      pattern: {
        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
      },
    },
    firstName: {
      pattern: {
        value: /^[^0-9^!@#$%^&*()_]{1,50}$/,
      },
    },
    amount: {
      pattern: {
        value: /^[+-]?\d+(\.\d+)?$/,
      },
    },
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} control={control} register={register} errors={errors}>
      <Field
        name="firstName"
        type="text"
        required
        className={oldTemplate ? 'oldTemplate' : 'input__half'}
        format={formats.firstName}
      />
      <Field
        name="lastName"
        type="text"
        required
        className={oldTemplate ? 'oldTemplate' : 'input__half'}
        format={formats.firstName}
      />
      <Field name="email" type="email" required format={formats.email} />
      <Select name="subject" options={configureList} />
      <Field
        name="amount"
        onChange={(event) => amountHandler(event)}
        type="text"
        className="input__half"
        format={formats.amount}
        value={amountValue}
      />
      <Controller
        name="date"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <Field
            onChange={onChange}
            name={name}
            control={control}
            type="date"
            selected={value}
            className="input__half"
          />
        )}
      />
      <TextArea name="message" />
      <Field name="policy" type="checkbox" required />
      <FormButton className={isFetching && 'is-fetching'} oldTemplate={oldTemplate}>
        {isFetching ? t(`form.fetching`) : t(`form.send`)}
      </FormButton>
      <FormMessage status={formIsSuccess} />
    </Form>
  );
};

export default MainSectionForm;
