import styled from 'styled-components';

export const DefaultLogo = styled.p`
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.textMainLighter};

  span {
    color: ${(props) => props.customColor ?? props.theme.accent};
  }
  sup {
    color: #fff;
    border-radius: 12px;
    font-size: 10px;
    background: #2b313b;
    padding: 2px 4px 2px 4px;
    top: -4px;
    position: relative;
    margin: 0 2px;
  }

  &.is-small {
    font-size: 18px;

    @media (min-width: 375px) {
      font-size: 24px;
    }
  }
`;

export const LastaLogo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;

  svg {
    color: ${({ theme, templateVariant }) => templateVariant && theme.logoColor[templateVariant]};
  }

  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.03em;
    text-transform: lowercase;
    color: ${({ theme, templateVariant }) => templateVariant && theme.logoColor[templateVariant]};
  }

  &.is-footer {
    svg,
    span {
      color: ${({ theme, templateVariant }) =>
        templateVariant && theme.footerLogoColor[templateVariant]};
    }
  }
`;

export const OldLogo = styled.p`
  font-weight: 700;
  line-height: 32px;
  color: ${(props) => props.theme.textMainLighter};
  font-size: 14px;
  white-space: nowrap;
`;
