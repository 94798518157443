import React from 'react';
import { useTranslation } from 'react-i18next';
import config from '@/config.json';
import { themes } from '@/themes';
import {
  GridItemHeadline,
  GridItemText,
  GridList,
  GridListItem,
  Section,
  SectionBox,
  SectionHeadline,
  SectionWrapper,
} from './styled';

const DefaultTemplate = () => {
  const { t } = useTranslation();
  const theme = themes[config?.TEMPLATE ?? 'default'][config.THEME ?? 'dark'];

  const getColor = (() => {
    switch (config?.DESCRIPTOR_NAME) {
      case 'PROTECT247.PRO':
        return '#E9EAEE';
      case 'PROTECT247.LIVE':
        return 'linear-gradient(180deg, #FFF 0%, #E4F9F0 55.21%, rgba(240, 250, 252, 0.00) 100%), #E8F7EC';
      case 'PROTECT247.SHOP':
        return '#ECEEF5';
      case 'PROTECT247.MOBI':
        return '#ADCBB6';
      case 'PROTECT247.LINK':
        return '#E7E7F2';
      case 'KIDGY.MOBI':
        return '#E3EEF6';
      case 'SPOTTRACK.PRO':
        return '#E3ECF6';
      case 'SPOTTRACK.INFO':
        return '#E0F3F4';
      case 'SPOTTRACK.SHOP':
        return '#ECECFA';
      case 'SEARQLE.PRO':
        return '#FAF6EF';
      case 'SEARQLE.INFO':
        return '#DBEFFF';
      case 'SEARQLE.STORE':
        return '#ECECFF';
      case 'SEARQLE.CLICK':
        return '#E7F4F5';
      case 'SEARQLE.SITE':
        return '#E2E3F5';
      default:
        return theme.backgroundColorDarker;
    }
  })();

  return (
    <Section id="faq" customBG={getColor}>
      <SectionWrapper>
        <SectionBox>
          <SectionHeadline>{t(`questionSection.billing.headline`)}</SectionHeadline>
          <GridList column={3}>
            <GridListItem>
              <GridItemHeadline>
                {t(`questionSection.billing.elementHeadline1`, {
                  descriptor: config.TRADE_NAME,
                  descriptor_domain: config.DESCRIPTOR_DOMAIN,
                })}
              </GridItemHeadline>
              <GridItemText>
                {t(`questionSection.billing.elementDescription1`, {
                  descriptor: config.TRADE_NAME,
                  descriptor_domain: config.DESCRIPTOR_DOMAIN,
                })}
              </GridItemText>
            </GridListItem>
            <GridListItem>
              <GridItemHeadline>{t(`questionSection.billing.elementHeadline2`)}</GridItemHeadline>
              <GridItemText>{t(`questionSection.billing.elementDescription2`)}</GridItemText>
            </GridListItem>
            <GridListItem>
              <GridItemHeadline>{t(`questionSection.billing.elementHeadline3`)}</GridItemHeadline>
              <GridItemText>{t(`questionSection.billing.elementDescription3`)}</GridItemText>
            </GridListItem>
          </GridList>
        </SectionBox>
        <SectionBox>
          <SectionHeadline>{t(`questionSection.others.headline`)}</SectionHeadline>
          <GridList column={4}>
            <GridListItem>
              <GridItemHeadline>{t(`questionSection.others.elementHeadline1`)}</GridItemHeadline>
              <GridItemText>
                {t(`questionSection.others.elementDescription1`, {
                  descriptor: config.TRADE_NAME,
                  descriptor_domain: config.DESCRIPTOR_DOMAIN,
                })}
              </GridItemText>
            </GridListItem>
            <GridListItem>
              <GridItemHeadline>{t(`questionSection.others.elementHeadline2`)}</GridItemHeadline>
              <GridItemText>{t(`questionSection.others.elementDescription2`)}</GridItemText>
            </GridListItem>
            <GridListItem>
              <GridItemHeadline>{t(`questionSection.others.elementHeadline3`)}</GridItemHeadline>
              <GridItemText
                dangerouslySetInnerHTML={{
                  __html: t('questionSection.others.elementDescription3', {
                    descriptor_email: config.MAILER_SUPPORT_EMAIL,
                    descriptor: config.TRADE_NAME,
                    descriptor_domain: config.DESCRIPTOR_DOMAIN,
                  }),
                }}
              />
            </GridListItem>
            <GridListItem>
              <GridItemHeadline>{t(`questionSection.others.elementHeadline4`)}</GridItemHeadline>
              <GridItemText>{t(`questionSection.others.elementDescription4`)}</GridItemText>
            </GridListItem>
          </GridList>
        </SectionBox>
      </SectionWrapper>
    </Section>
  );
};

export default DefaultTemplate;
